@import "settings";

@import "foundation";

body {
  background: url('/concrete_seamless.png');
}

.small-centered {
  margin-left: auto;
  margin-right: auto;
  float: none !important;
}

.dropTarget {
  width: 300px;
  height: 200px;
  border: 5px dashed brown;
}

canvas {
  padding-left: 0;
    padding-right: 0;
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: 200px;
}

input[type=range] {
  -webkit-appearance: none;
  width: 100%;
  margin: 7.3px 0;
}
input[type=range]:focus {
  outline: none;
}
input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  box-shadow: 0.1px 0.1px 1px #000000, 0px 0px 0.1px #0d0d0d;
  background: #e7e7e7;
  border-radius: 1.3px;
  border: 0.2px solid #010101;
}
input[type=range]::-webkit-slider-thumb {
  box-shadow: 0px 0px 1px #000000, 0px 0px 0px #0d0d0d;
  border: 0px solid #000000;
  height: 23px;
  width: 29px;
  border-radius: 13px;
  background: #008cba;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -7.5px;
}
input[type=range]:focus::-webkit-slider-runnable-track {
  background: #f4f4f4;
}
input[type=range]::-moz-range-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  box-shadow: 0.1px 0.1px 1px #000000, 0px 0px 0.1px #0d0d0d;
  background: #e7e7e7;
  border-radius: 1.3px;
  border: 0.2px solid #010101;
}
input[type=range]::-moz-range-thumb {
  box-shadow: 0px 0px 1px #000000, 0px 0px 0px #0d0d0d;
  border: 0px solid #000000;
  height: 23px;
  width: 29px;
  border-radius: 13px;
  background: #008cba;
  cursor: pointer;
}
input[type=range]::-ms-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
input[type=range]::-ms-fill-lower {
  background: #dadada;
  border: 0.2px solid #010101;
  border-radius: 2.6px;
  box-shadow: 0.1px 0.1px 1px #000000, 0px 0px 0.1px #0d0d0d;
}
input[type=range]::-ms-fill-upper {
  background: #e7e7e7;
  border: 0.2px solid #010101;
  border-radius: 2.6px;
  box-shadow: 0.1px 0.1px 1px #000000, 0px 0px 0.1px #0d0d0d;
}
input[type=range]::-ms-thumb {
  box-shadow: 0px 0px 1px #000000, 0px 0px 0px #0d0d0d;
  border: 0px solid #000000;
  height: 23px;
  width: 29px;
  border-radius: 13px;
  background: #008cba;
  cursor: pointer;
  height: 8.4px;
}
input[type=range]:focus::-ms-fill-lower {
  background: #e7e7e7;
}
input[type=range]:focus::-ms-fill-upper {
  background: #f4f4f4;
}


